import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isFixed, setIsFixed] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        if (menuOpen) {
            setClosing(true);
            setTimeout(() => {
                setMenuOpen(false);
                setClosing(false);
                document.body.classList.remove('overflow-hidden');
            }, 500);
        } else {
            setMenuOpen(true);
            document.body.classList.add('overflow-hidden');
        }
    };

    const toggleDropdown = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };

    const handleMenuItemClick = (event, path, hash) => {
        event.preventDefault();
        setMenuOpen(false);
        setActiveDropdown(null);
        document.body.classList.remove('overflow-hidden');

        if (hash) {
            navigate(path);
            setTimeout(() => {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        } else {
            navigate(path);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 100) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className={`header ${menuOpen ? 'notfixed' : ''} ${isFixed ? 'fixed' : ''}`}>
                <div className="container-lg mainHeader">
                    <div className="logo">
                        <Link to='/'><img src="https://sayahomes.com/assets/images/logo5.svg" alt="Saya Homes Logo" title='Saya Homes Logo' /></Link>
                    </div>
                    <div className="readmore mt-0 w-auto">
                        <button
                            role="button"
                            className={`button menuBtn ${menuOpen ? 'closeMenuBtn' : ''}`}
                            onClick={toggleMenu}
                        >
                            <span className="icon"><i className="fa-solid fa-arrow-right"></i></span>
                            <span className="text">{menuOpen ? 'Close' : 'Menu'}</span>
                            <span className="icon"><i className="fa fa-bars"></i></span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`mbMenuContainer ${menuOpen ? 'active' : ''} ${closing ? 'closing' : ''}`} style={{ display: menuOpen || closing ? 'block' : 'none' }}>
                <div className="mbMenu scroller">
                    <ul className="list-inline">
                        <li><a href="/" onClick={(e) => handleMenuItemClick(e, '/')} >Home</a></li>
                        <li className={`hasChild ${activeDropdown === 0 ? 'active' : ''}`}>
                            <a href="javascript:;" onClick={() => toggleDropdown(0)}>About Us <i className="fa fa-plus"></i></a>
                            <div className="dropdown" style={{ display: activeDropdown === 0 ? 'block' : 'none' }}>
                                <ul className="list-inline">
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/about-us')}>Overview</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/about-us', 'visionmission')}>Vision & Mission</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/about-us', 'meetourfounder')}>Meet Our Founder</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/about-us', 'awards')}>Awards & Recognition</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/about-us', 'customer')}>Customer Relationship</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/about-us', 'partners')}>Partnerships</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/about-us', 'appreciation')}>Appreciation Letter</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className={`hasChild ${activeDropdown === 1 ? 'active' : ''}`}>
                            <a href="javascript:;" onClick={() => toggleDropdown(1)}>
                                Projects <i className="fa fa-plus"></i>
                            </a>
                            <div className="dropdown" style={{ display: activeDropdown === 1 ? 'block' : 'none' }}>
                                <ul className="list-inline">
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/residential')}>Residential</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e, '/commercial')}>Commercial</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className={`hasChild ${activeDropdown === 2 ? 'active' : ''}`}>
                            <a href="javascript:;" onClick={() => toggleDropdown(2)}>Media <i className="fa fa-plus"></i></a>
                            <div className="dropdown" style={{ display: activeDropdown === 2 ? 'block' : 'none' }}>
                                <ul className="list-inline">
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/in-the-media')}>In the Media</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/events')}>Events</a></li>
                                    <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/blogs')}>Blogs</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/nri-corner')}>NRI Corner</a></li>
                        <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/csr')}>CSR</a></li>
                        <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/construction-updates')}>Construction Updates</a></li>
                        <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/customer-supports')}>Customer Support</a></li>
                        <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/careers')}>Careers</a></li>
                        <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/contact-us')}>Contact Us</a></li>
                        <li><a href="javascript:;" onClick={(e) => handleMenuItemClick(e,'/channel-partner-registration')}>Register as a Channel Partner</a></li>
                    </ul>
                    <div className="header-bottom w-100">
                        <ul className="list-inline socialBtn flex-row">
                            <li><a href="https://www.facebook.com/sayahomesofficial" target="_blank" rel="noreferrer" data-toggle="tooltip" data-placement="bottom" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://www.instagram.com/sayahomes/" target="_blank" rel="noreferrer" data-toggle="tooltip" data-placement="bottom" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCzicWlJx_HQtk4llMO8mMpw" target="_blank" rel="noreferrer" data-toggle="tooltip" data-placement="bottom" title="YouTube"><i className="fab fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/saya-homes" target="_blank" rel="noreferrer" data-toggle="tooltip" data-placement="bottom" title="LinkedIn"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
