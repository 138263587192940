import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import FooterAll from '../../components/footer2';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function Events() {
    const [events, setEvents] = useState([]);
    const canonical = window.location.href.replace(/^https?:\/\/www\./, 'https://');

    useEffect(() => {
        // Fetch events data from API
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://ecis.in/apis/sayahomes/events/get');
                const sorted = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setEvents(sorted);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <div>
            <Helmet>
            <title>Saya Homes | Saya Events</title>
            <meta name="description" content="Stay updated with our latest Events by Saya Homes, here we showcase exciting community gatherings, property launches, and exclusive offers." />
            <meta name="keywords" content="saya event, event" />
            <link rel="canonical" href={canonical}/>
            </Helmet>
            <Navbar />
            <div className="emptyBox"></div>
            <div className="pageTitle py-5 bg-orange w-100">
                <div className="container-lg">
                    <h1 className="display-4 mb-0">Saya Events</h1>
                </div>
            </div>
            <div className="breadcrumbContainer">
                <div className="container-lg">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Events</li>
                    </ol>
                </div>
            </div>
            <div className="w-100 padding position-relative">
                <div className="container-lg">
                    <div className="inside-blogsArchive">
                        <div className="row gap-row">
                            {events.map((event) => (
                                <div key={event.id} className="col-md-4 newsBox">
                                    <Link
                                        className="inner h-100"
                                        to={`/event-details/${event.ev_group}`}
                                        state={{ meta_title: event.ev_meta_title, meta_keyword: event.ev_meta_key, meta_description: event.ev_meta_desc }}
                                        >
                                        <div className="img-fluid mt-0 position-relative">
                                            <img
                                                src={`https://www.sayahomes.com/uploads/events/${event.ev_image}`}
                                                alt={event.ev_title}
                                                className="h-100 object-cover"
                                                title={event.ev_title}
                                            />
                                        </div>
                                        <article>
                                            <h5 className="news-title mt-4">{event.ev_title}</h5>
                                            <div className="date justify-content-between">
                                            <small>
                                                {new Date(event.ev_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}
                                            </small>
                                        </div>

                                        </article>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <FooterAll />
        </div>
    );
}

export default Events;
