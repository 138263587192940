import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import { Helmet } from 'react-helmet';
function Awards() {
    useEffect(() => {
        var swiper = new Swiper('.news-slider', {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                620: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        });
    }, []);

    const navigate = useNavigate();

    const handleMenuItemClick = (event, path, hash) => {
        event.preventDefault();
        document.body.classList.remove('overflow-hidden');

        if (hash) {
            navigate(path);
            setTimeout(() => {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        } else {
            navigate(path);
        }
    };

    return (
        <div>
            
            <div className="w-100 padding position-relative animate-section5">
                <div className="container-lg">
                    <div className="heading mx-auto text-center">
                        <h6 className="text-orange">Awards & Recognition</h6>
                        <h2 className="h1 text-serif mb-0">Quality Acknowledged By The World</h2>
                    </div>
                    <div className="swiper news-slider blogContainer">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/a8640f21-c37f-41bf-998b-40b17c805372.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Prime Commercial Project for Saya Piazza">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/a8640f21-c37f-41bf-998b-40b17c805372-th.webp" alt="Saya Homes" className="h-100 object-cover" title="Prime Commercial Projects" /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Prime Commercial Project</h5>
                                        <p className="mb-0">Times Realty & Infrastructure Conclave 2024. </p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/ee0b9226-6537-4ff9-9297-51e106c8503f.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Environmental Friendly Projects for Saya Gold Avenue">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/ee0b9226-6537-4ff9-9297-51e106c8503f-th.webp" alt="Saya Homes" className="h-100 object-cover" title="Environmental Friendly Projects" /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Environmental Friendly</h5>
                                        <p className="mb-0">Times Realty & Infrastructure Conclave 2024. </p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/ed32f38e-fb30-4b25-9887-421895d98e88.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Quality Construction">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/ed32f38e-fb30-4b25-9887-421895d98e88-th.webp" alt="Saya Homes" className="h-100 object-cover" title="Excellence in Quality Construction" /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Quality Construction</h5>
                                        <p className="mb-0">ET Now Realty Conclave 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/3563c150-0025-426b-a2e0-30c6068e3c61.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Luxury Segment Homes">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/3563c150-0025-426b-a2e0-30c6068e3c61-th.webp" alt="Saya Homes" className="h-100 object-cover" title="Excellence in Luxury Segment Homes" /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Luxury Segment Homes</h5>
                                        <p className="mb-0">ET Now Realty Conclave 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/11f3e7c5-30cd-4a98-8190-341ee4a0fcb0.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Real Estate Developer of the Year">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/11f3e7c5-30cd-4a98-8190-341ee4a0fcb0-th.webp" alt="Saya Homes" className="h-100 object-cover" title="Real Estate in Developer of The Year" /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Real Estate Developer of the Year</h5>
                                        <p className="mb-0">ET Now Realty Conclave 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/1.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="ICONIC Real Estate Brand of The Year - Saya Group">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/th1.webp" alt="Saya Homes" className="h-100 object-cover" title="Times Business Awards" /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Iconic Real Estate Brand of The Year - Saya Group</h5>
                                        <p className="mb-0">Times Business Awards, North 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/2.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Real Estate- Excellence In The Luxury Residential Projects">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/th2.webp" alt="Saya Homes" className="h-100 object-cover" title="Times Business Awards" /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Real Estate - Excellence in The Luxury Residential Projects</h5>
                                        <p className="mb-0">Times Business Awards, North 2024</p>
                                    </article>
                                </a>
                            </div>
                            
                        </div>
                        <div className="bottomControls">
                            <div className="swiper-button-prev solid"></div>
                            <div className="viewmore">
                            <a href="javascript:;" className="button stroke" onClick={(e) => handleMenuItemClick(e, '/about-us', 'awards')}>View All Awards</a>
                                {/* <Link to="/about-us" className="button stroke">View All Awards</Link> */}
                                </div>
                            <div className="swiper-button-next solid"></div>
                        </div>
                    </div>
                </div> </div>
        </div>
    )
}

export default Awards