import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Swiper from 'swiper';
import VideoModal from '../utils/videoModel';

function Testimonials() {
    const [modalData, setModalData] = useState({ title: '', src: '' });
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (title, src) => {
    setModalData({ title, src });
    setShowModal(true);
    };

    const handleCloseModal = () => {
    setModalData({ title: '', src: '' });
    setShowModal(false);
    };

    const swiperRef = useRef(null);

    useEffect(() => {
        swiperRef.current = new Swiper(".testim-slider", {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: true,
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                620: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            }
        });

    }, []);

    return (
    <>
    <div className="w-100 position-relative padding bg-secondary-light overflow-hidden animate-section4">
        <div className="container-lg">
            <div className="heading mx-auto text-center">
                <h6>Testimonials</h6>
                <h2 className="h1 text-serif mb-0">What Our Customers Say</h2>
            </div>
            <div className="swiper testim-slider animate">
                <div className="swiper-wrapper">
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a
                                href="#video-Modal"
                                
                                className="img-fluid position-relative h-100"
                                onClick={() => handleShowModal('Saya SouthX - Unrivalled Destination Unforgettable Experiences', 'https://www.youtube.com/embed/PRgoLcERlOQ?rel=0&amp;autoplay=1')}
                            >
                                <img
                                    src="https://sayahomes.com/assets/images/PRgoLcERlOQ.webp"
                                    title="Saya SouthX - Unrivalled Destination Unforgettable Experiences"
                                    alt="Saya SouthX - Unrivalled Destination Unforgettable Experiences"
                                    className="h-100 object-cover"
                                />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <b>Mr Pradeep Rawat</b>
                                    <small>Saya SouthX - Unrivalled Destination Unforgettable Experiences</small>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a href="#video-Modal" onClick={() => handleShowModal('Saya SouthX: Unrivalled Destination Unforgettable Experiences, Must Invest to get Best Return', 'https://www.youtube.com/embed/MFdoV42c7Bk?rel=0&amp;autoplay=1')}  className="img-fluid position-relative h-100" >
                                <img src="https://sayahomes.com/assets/images/MFdoV42c7Bk.webp" title="Saya SouthX: Unrivalled Destination Unforgettable Experiences, Must Invest to get Best Return" alt="Saya SouthX: Unrivalled Destination Unforgettable Experiences, Must Invest to get Best Return" className="h-100 object-cover" />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <b>Mr Vishal Bhardwaj</b>
                                    <small>Saya SouthX: Unrivalled Destination Unforgettable Experiences, Must Invest to get Best Return</small>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a href="#video-Modal" 
                            onClick={() => handleShowModal('Saya South X Excavation', 'https://www.youtube.com/embed/M5_9_4AppSg?rel=0&amp;autoplay=1')}
                             className="img-fluid position-relative h-100" >
                                <img src="https://sayahomes.com/assets/images/testim-gold-indrapuram.webp" title="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 4" alt="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 3" className="h-100 object-cover" />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <b>Mr Prakash Ranawat</b>
                                    <small>Owns a property in Saya Gold Avenue</small>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a href="#video-Modal" 
                            onClick={() => handleShowModal('Saya South X Excavation', 'videos/Happy-Residents-GoldAvenue-4.mp4')}
                             className="img-fluid position-relative h-100" >
                                <img src="https://sayahomes.com/assets/images/Video-4_1.webp" title="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 4" alt="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 3" className="h-100 object-cover" />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <small>Owns a property in Saya Gold Avenue</small>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a href="#video-Modal" 
                            onClick={() => handleShowModal('Saya South X Excavation', 'videos/customers3.mp4')}
                             className="img-fluid position-relative h-100" >
                                <img src="https://sayahomes.com/assets/images/home3_1.avif" title="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 4" alt="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 3" className="h-100 object-cover" />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <b>Mr Vikash</b>
                                    <small>Owns a property in Saya Gold Avenue</small>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a href="#video-Modal" 
                            onClick={() => handleShowModal('Saya South X Excavation', 'https://sayahomes.com/videos/The-best-in-Delhi-NCR.mp4')}
                             className="img-fluid position-relative h-100" >
                                <img src="https://sayahomes.com/assets/images/Video-1.avif" title="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 4" alt="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 3" className="h-100 object-cover" />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <b>Mr Vajay </b>
                                    <small>Owns a property in Saya Gold Avenue</small>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a href="#video-Modal" 
                            onClick={() => handleShowModal('Saya South X Excavation', 'https://sayahomes.com/videos/Real-Estate-reviews-Gold-Avenue.mp4')}
                             className="img-fluid position-relative h-100" >
                                <img src="https://sayahomes.com/assets/images/Video-2.avif" title="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 4" alt="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 3" className="h-100 object-cover" />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <b>Mr Manoj</b>
                                    <small>Owns a property in Saya Gold Avenue</small>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="swiper-slide testimBox">
                        <div className="testim-video">
                            <a href="#video-Modal" 
                            onClick={() => handleShowModal('Saya South X Excavation', 'https://sayahomes.com/videos/Happy-Residents-GoldAvenue.mp4')}
                             className="img-fluid position-relative h-100" >
                                <img src="https://sayahomes.com/assets/images/Video-3_1.avif" title="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 4" alt="Saya Homes - Real Estate Company in Delhi NCR - Testimonial - Video 3" className="h-100 object-cover" />
                                <i className="fa fa-play videoBtn shadow"></i>
                                <div className="testim-name">
                                    <b>Mr Anil</b>
                                    <small>Owns a property in Saya Gold Avenue</small>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="bottomControls">
                    <div className="swiper-button-prev solid bg-secondary text-dark"></div>
                    <div className="viewmore"><Link to="/testimonials" className="button stroke">View All Testimonials</Link></div>
                    <div className="swiper-button-next solid bg-secondary text-dark"></div>
                </div>
            </div>
        </div>
    </div>
    <VideoModal showModal={showModal} modalData={modalData} handleCloseModal={handleCloseModal} />
    </>
    );
}

export default Testimonials;
