import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import Overview from './home/overview';
import Blogs from './home/blogs';
import FeaturedProjects from './home/featured-projects';
import Awards from './home/awards';
import Testimonials from './home/testimonials';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';


function Home() {
  const canonical = window.location.href.replace(/^https?:\/\/www\./, 'https://');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const cookieExists = document.cookie.split('; ').find(row => row.startsWith('jquery_popup='));
    if (!cookieExists) {
      setShowModal(true);
    }
  }, []);

  const handleAgree = () => {
    const d = new Date();
    d.setTime(d.getTime() + 2 * 60 * 60 * 1000);
    document.cookie = `jquery_popup=sessionexists; expires=${d.toUTCString()}; path=/`;
    setShowModal(false);
  };


  return (
  <div className='wrapper'>
    <Helmet>
      {/* <title>Saya Homes - Delhi-NCR Leading Real Estate Developer</title> */}      
    <meta name="description" content="Saya Homes is a trusted name in Delhi-NCR real estate industry, renowned for delivering exceptional residential & commercial projects in Greater Noida West, Ghaziabad. "/>
      <meta name="language" content="English" />
      <meta name="Publisher" content="Saya Homes" />
      <meta name="Author" content="Saya Homes" />
      <meta name="audience" CONTENT="all" />
      <meta name="copyright" content="https://sayahomes.com/" />
      <meta name="web_author" content="https://sayahomes.com/" />
      <meta name="host" CONTENT="https://sayahomes.com/" />
      <meta name="contactOrganization" CONTENT="https://sayahomes.com/" />
      <meta name="contactStreetAddress1" CONTENT="Delhi-NCR Leading Real Estate Developer" />
      <meta name="contactCity" CONTENT="Delhi NCR" />
      <meta name="contactCountry" CONTENT="India" />
      <meta name="identifier-URL" CONTENT="https://sayahomes.com/" />
      <meta name="distribution" CONTENT="Global" />
      <meta name="revisit-after" content="Daily" />
      <meta name="document-type" content="Public" />
      <meta name="Expires" content="never" />
      <meta property="og:url" content="article" />
      <meta name="allow-search" content="yes" />
      <meta name="Rating" content="General" />
      <meta property="og:url" content="https://sayahomes.com/" />
      <link rel="canonical" href={canonical}/>
      {/* <meta property="og:title" content="Delhi-NCR Leading Real Estate Developer" />
      <meta property="og:description" content="Saya Homes is a trusted name in Delhi-NCR real estate industry, renowned for delivering exceptional residential and commercial projects." /> */}
      <meta property="og:title" content='Saya Homes - Delhi-NCR Leading Real Estate Developer' />
      <meta property="og:site_name" content='Saya' />
      <meta property="og:url" content='https://sayahomes.com/' />
      <meta property="og:description" content='Saya Homes is a top real estate developer in Delhi-NCR, specializing in high-quality residential and commercial projects. Find your dream home with us today!' />
      <meta property="og:type" content='website' />
      <meta property="og:image" content="https://sayahomes.com/assets/images/logo5.svg" /> 
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="Saya Homes - Delhi-NCR Leading Real Estate Developer" />
      <meta name="twitter:site" content="@SayaHomes" />
      <meta name="twitter:description" content="Saya Homes is a top real estate developer in Delhi-NCR, specializing in high-quality residential and commercial projects. Find your dream home with us today!" />
      <meta name="twitter:image" content="https://sayahomes.com/assets/images/logo5.svg" />
      <meta name="twitter:image:alt" content="SayaHomes" />
    </Helmet>
    <Navbar />
    <div id="carouselExample" className="banner w-100 scrollto carousel slide" data-pause="false" data-ride="carousel" data-interval="3000">
      <div className="carousel-inner h-100">
        <div className="carousel-item h-100 active">
          <picture>
            <source media="(max-width: 425px)" srcSet="./assets/images/magzine-banner-mob2.webp" />
            <img src="./assets/images/magzine-banner2.webp" className="d-block w-100 h-100 object-cover" alt="Banner" />
          </picture>
        </div>

        <div className="carousel-item h-100">
          <picture>
            <source media="(max-width: 425px)" srcSet="./assets/images/magzine-banner-mob3.webp" />
            <img src="./assets/images/magzine-banner3.webp" className="d-block w-100 h-100 object-cover" alt="Banner" />
          </picture>
        </div>

        <div className="carousel-item h-100">
          <picture>
            <source media="(max-width: 425px)" srcSet="./assets/images/magzine-banner-mob4.webp" />
            <img src="./assets/images/magzine-banner4.webp" className="d-block w-100 h-100 object-cover" alt="Banner" />
          </picture>
        </div>

        <div className="carousel-item h-100">
          <picture>
            <source media="(max-width: 425px)" srcSet="./assets/images/magzine-banner-mob5.webp" />
            <img src="./assets/images/magzine-banner5.webp" className="d-block w-100 h-100 object-cover" alt="Banner" />
          </picture>
        </div>

        {/* <div className="carousel-item h-100 active">
          <picture>
            <source media="(max-width: 425px)" srcSet="./assets/images/banner/Banner-commercial-m.jpg" />
            <img src="./assets/images/banner/Banner-commercial.jpg" className="d-block w-100 h-100 object-cover" alt="Banner" />
          </picture>
        </div>

        <div className="carousel-item h-100">
          <picture>
            <source media="(max-width: 425px)" srcSet="./assets/images/banner/Banner-retail-m.jpg" />
            <img src="./assets/images/banner/Banner-retail.jpg" className="d-block w-100 h-100 object-cover" alt="Banner" />
          </picture>
        </div>
        <div className="carousel-item h-100">
          <picture>
            <source media="(max-width: 425px)" srcSet="./assets/images/banner/8-m.jpg" />
            <img src="./assets/images/banner/8.jpg" className="d-block w-100 h-100 object-cover" alt="Banner" />
          </picture>
        </div> */}
      </div>
      <a className="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>      
      <a className="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
    <Overview />
    <FeaturedProjects />
    <Awards />
    <Testimonials />
    <Blogs />
    <Footer />
    {showModal && (
      <div className="modal testim-modal fade bd-example-modal-xl show" id="disclaimersaya" tabindex="-1" role="dialog" aria-labelledby="sapnaModalTitle" aria-modal="true" style={{paddingRight: "17px", display: "block"}}>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content border-0 shadow rounded-0">
            <div className="modal-header rounded-0"><h5 className="modal-title text-white">DISCLAIMER</h5></div>
            <div className="modal-body p-0 bg-white">
              <div className="disclaimerbody my-3 px-3">
                <p><small>This disclaimer (“Disclaimer”) will be applicable to the Website. By using or accessing the Website you agree with the Disclaimer without any qualification or limitation. The Company reserves the right to add, alter or delete material from the Website at any time and may, at any time, revise these Terms without notifying you.</small></p><small>
                </small><p><small> You are bound by any such amendments, and the Company, therefore, advise that you periodically visit this page to review the current Terms. The Website and all its content are provided with all faults on an “as is” and “as available” basis. No information given under this Website creates a warranty or expands the scope of any warranty that cannot be disclaimed under applicable law. Your use of the Website is solely at your own risk.</small></p>
                <p><small>This Website is for guidance only. It does not constitute part of an offer or contract. Design &amp; specifications are subject to change without prior notice. Computer generated images are the artist’s impression and are indicative of the actual designs. The particulars contained in the mentioned details of the Projects/developments undertaken by the Company including depicting banners/posters of the Project. The contents are being modified in terms of the stipulations/recommendations under the Real Estate Regulation Act, 2016 and Rules made thereunder (“RERA”) and accordingly may not be fully in line thereof as of date. You are therefore required to verify all the details, including area, amenities, services, terms of sales and payments and other relevant terms independently with the sales team/ company prior to concluding any decision for buying any unit(s) in any of the said projects. </small></p>
                <p><small>Till such time the details are fully updated, the said information will not be construed as an advertisement. To find out more about a project/development, please telephone our sales centers or visit our sales office during opening hours and speak to one of our sales staff. In no event will the Company be liable for claims made by the users, including seeking any cancellation for any of the inaccuracies in the information provided on this Website, though all efforts have to be made to ensure accuracy.</small></p>
                <p><small>The Company will under no circumstance be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this Website.</small></p>
              </div>
              <button className="modal-close text-serif" id="iagreebtn" onClick={handleAgree}><span>I HAVE READ &amp; UNDERSTOOD THE DISCLAIMER</span><strong className="mb-0 h6">I Agree</strong></button>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
  );
}

export default Home;
