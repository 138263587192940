import React from 'react'

function VisonMission() {
  return (
    <div>
           <div className="w-100 padding position-relative bg-orange text-dark overflow-hidden" id="vision">
           <a id="visionmission" name="visionmission"></a>
                    <div className="container-lg">
                        <div className="row no-gutters mb-4 mb-lg-0">
                            <div className="col-lg-6 col-md-7 gridBoxText bg-white d-flex align-items-center">
                                <div className="gridIcon"><img src="https://sayahomes.com/assets/images/icons/vision.svg" alt='Saya Homes' /></div>
                                <div className="inner p-5">
                                    <div className="heading">
                                        <h2 className="h1 text-serif text-orange mb-0">Vision</h2>
                                    </div>
                                    <p className="mb-0">With 25 years of excellence in the luxury real estate industry, Saya Group aspires to be the benchmark of excellence and trust. Driven by trust, sustainability, quality, transparency, and innovation, we transform dreams into tangible realities, exceed expectations, and leave enduring legacies.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 gridBoxImg">
                                <img src="https://sayahomes.com/assets/images/vision.webp" className="h-100 object-cover" alt='Saya Homes' />
                            </div>
                        </div>
                        <div className="row no-gutters mb-4 mb-lg-0 flex-row-reverse">
                            <div className="col-lg-6 col-md-7 gridBoxText bg-white d-flex align-items-center">
                                <div className="gridIcon"><img src="https://sayahomes.com/assets/images/icons/mission.svg" alt='Saya Homes' /></div>
                                <div className="inner p-5">
                                    <div className="heading">
                                        <h2 className="h1 text-serif text-orange mb-0">Mission</h2>
                                    </div>
                                    <p className="mb-0">At Saya Group, our mission is to develop luxury real estate projects that epitomize sustainability, quality, transparency, trust and innovation. We are dedicated to securing prime locations and ensuring our land is always fully paid up. By transforming visionary concepts into exceptional living & commercial spaces, we aim to exceed customer expectations, foster trust and long-term relationships, and contribute positively to the communities we serve.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 gridBoxImg">
                                <img src="https://sayahomes.com/assets/images/mission.webp" className="h-100 object-cover" alt='Saya Homes' />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-lg-6 col-md-7 gridBoxText bg-white d-flex align-items-center">
                                <div className="gridIcon"><img src="https://sayahomes.com/assets/images/icons/core-value.svg" alt='Saya Homes' /></div>
                                <div className="inner p-5">
                                    <div className="heading">
                                        <h2 className="h1 text-serif text-orange mb-0">Purpose</h2>
                                    </div>
                                    <p className="mb-0">Our purpose is to lead the future of real estate by turning visionary concepts into realities that surpass expectations. By exceeding the needs of both investors and residents, we aim to create exceptional value and redefine the luxury experience. Ultimately, we are driven by a desire to transform ambitious dreams into tangible realities, leaving a lasting legacy in the world of luxury real estate.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 gridBoxImg">
                                <img src="https://sayahomes.com/assets/images/core-value3.webp" className="h-100 object-cover" alt='Saya Homes' />
                            </div>
                        </div>
                    </div>
                </div>
    </div>
  )
}

export default VisonMission