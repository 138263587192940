import React, { useEffect } from 'react'
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function AwardsRecognition() {
    useEffect(() => {
        var awards = new Swiper(".news-slider", {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                620: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1600: {
                    slidesPerView: 3,
                    spaceBetween: 80,
                },
            }
        });
    }, []);
    return (
        <div>
            <div className="w-100 padding bg-orange position-relative animate-section5" id="awards-n-recognition">
                <a id="awards" name="awards"></a>
                <div className="container-lg">
                    <div className="heading">
                        <h6>Awards & Recognition</h6>
                        <h2 className="h1 text-serif mb-0">Quality Acknowledged By The World</h2>
                    </div>
                    <div className="swiper news-slider blogContainer">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/a8640f21-c37f-41bf-998b-40b17c805372.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Prime Commercial Project for Saya Piazza">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/a8640f21-c37f-41bf-998b-40b17c805372-th.webp" alt="Saya Homes" className="h-100 object-cover" title='Prime Commercial Projects' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Prime Commercial Project</h5>
                                        <p className="mb-0">Times Realty & Infrastructure Conclave 2024. </p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/ee0b9226-6537-4ff9-9297-51e106c8503f.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Environmental Friendly Projects for Saya Gold Avenue">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/ee0b9226-6537-4ff9-9297-51e106c8503f-th.webp" alt="Saya Homes" className="h-100 object-cover" title='Environmental Friendly Projects' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Environmental Friendly</h5>
                                        <p className="mb-0">Times Realty & Infrastructure Conclave 2024. </p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/ed32f38e-fb30-4b25-9887-421895d98e88.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Quality Construction">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/ed32f38e-fb30-4b25-9887-421895d98e88-th.webp" alt="Saya Homes" className="h-100 object-cover" title='Excellence in Quality Construction' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Quality Construction</h5>
                                        <p className="mb-0">ET Now Realty Conclave 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/3563c150-0025-426b-a2e0-30c6068e3c61.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Excellence in Luxury Segment Homes">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/3563c150-0025-426b-a2e0-30c6068e3c61-th.webp" alt="Saya Homes" className="h-100 object-cover" title='Excellence in Luxury Segment Homes' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Excellence in Luxury Segment Homes</h5>
                                        <p className="mb-0">ET Now Realty Conclave 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/11f3e7c5-30cd-4a98-8190-341ee4a0fcb0.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Real Estate Developer of the Year">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/11f3e7c5-30cd-4a98-8190-341ee4a0fcb0-th.webp" alt="Saya Homes" className="h-100 object-cover" title='Real Estate in Developer of The Year' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Real Estate Developer of the Year</h5>
                                        <p className="mb-0">ET Now Realty Conclave 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/1.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="ICONIC Real Estate Brand of The Year - Saya Group">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/th1.webp" alt="Saya Homes" className="h-100 object-cover" title='Times Business Awards' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Iconic Real Estate Brand of The Year - Saya Group</h5>
                                        <p className="mb-0">Times Business Awards, North 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/2.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Real Estate- Excellence In The Luxury Residential Projects">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/th2.webp" alt="Saya Homes" className="h-100 object-cover" title='Times Business Awards' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Real Estate - Excellence in The Luxury Residential Projects</h5>
                                        <p className="mb-0">Times Business Awards, North 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/3.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Real Estate Commercial Project Of The Year - Saya Southx">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/th3.webp" alt="Saya Homes" className="h-100 object-cover" title='Times Business Awards' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Real Estate Commercial Project Of The Year - Saya Southx</h5>
                                        <p className="mb-0">Times Business Awards, North 2024</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/2024/3.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Repeatedly honoured with the Titan Achievers Awards by Hindustan Times, including in 2024">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/2024/3.webp" alt="Saya Homes" className="h-100 object-cover" title='Titan Achievers Awards by Hindustan Times, 2024' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Repeatedly honoured with the Titan Achievers Awards by Hindustan Times, including in 2024</h5>
                                        <p className="mb-0"></p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/2024/5.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="   Recognised for Excellence in Retail Projects by ABP News in 2023">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/2024/5.webp" alt="Excellence in Retail Projects" className="h-100 object-cover" title='Excellence in Retail Projects By ABP News, 2024' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Recognised for Excellence in Retail Projects</h5>
                                        <p className="mb-0"></p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/saya_status_excellence.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="The Real Estate Leadership Conclave">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/saya_status_excellence.webp" alt="Retail Estate Leadership Conclave" className="h-100 object-cover" title='Retail Estate Leadership Conclave' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">The Real Estate Leadership Conclave</h5>
                                        <p className="mb-0">Saya Status, India's Tallest Mall,</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/best_retail_project_new.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Best Retail Project of the Year Award- Saya Status">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/best_retail_project_new.webp" alt="Best Retail Project of The Year Awards 2023" className="h-100 object-cover" title='Best Retail Project of The Year Awards 2023' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Best Retail Project of the Year Award- Saya Status</h5>
                                        <p className="mb-0">upcoming India's Tallest Mall on the Noida Expressway, soars to new heights!</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/Indian-Green-Building-Council.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Indian Green Building Council - Saya Zion">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/Indian-Green-Building-Council.webp" alt="IGBC Green Home" className="h-100 object-cover" title='IGBC Green Home' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Indian Green Building Council - Saya Zion</h5>
                                        <p className="mb-0">IGBC Green Home</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/20220531074406-IGBC_Green_Homes.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="IBS Green Home Awards - Saya Zion - Flats in Greater Noida West">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/20220531074406-IGBC_Green_Homes.webp" alt="Indian Green Building Council (IGBC)" className="h-100 object-cover" title='Indian Green Building Council (IGBC)' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">IBS Green Home Awards - Saya Zion - Flats in Greater Noida West</h5>
                                        <p className="mb-0">IGBC Green Homes</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/20220531074304-Best_Residential_Project_In_NCR.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Best Residential projects of Delhi NCR Awards - Saya Homes">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/20220531074304-Best_Residential_Project_In_NCR.webp" alt="Best Residential Projects in NCR" className="h-100 object-cover" title='Best Residential Projects in NCR' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Best Residential projects of Delhi NCR Awards - Saya Homes</h5>
                                        <p className="mb-0">Best Residential Project In NCR</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/20220531074236-Premium_Residential_Project.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="The Most Premium Residential projects in Greater Noida West Certified - Saya Zion">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/20220531074236-Premium_Residential_Project.webp" alt="SAYA Zion Greater Noida West" className="h-100 object-cover" title='SAYA Zion Greater Noida West' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">The Most Premium Residential projects in Greater Noida West Certified - Saya Zion</h5>
                                        <p className="mb-0">Premium Residential Project</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/20220531074210-Promising_Developer.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Promising Developer Awards - Saya Zion">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/20220531074210-Promising_Developer.webp" alt="Jury Award is Presented to Saya Zion" className="h-100 object-cover" title='Jury Award is Presented to Saya Zion' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Promising Developer Awards - Saya Zion</h5>
                                        <p className="mb-0">Promising Developer</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/20220531074126-Property_Observer_Jury_Award.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Properties Observer Jury Awards - Saya Homes">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/20220531074126-Property_Observer_Jury_Award.webp" alt="Property Observer Jury Award" className="h-100 object-cover" title='Worldwide Achiever Award' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Properties Observer Jury Awards - Saya Homes</h5>
                                        <p className="mb-0">Property Observer Jury Award</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/2024/7.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Received the Promising Developer Award from the Builder Council of India in 2014">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/2024/7.webp" alt="The Promising Developer Award From The Builder Council of India, 2024" className="h-100 object-cover" title='The Promising Developer Award From The Builder Council of India, 2024' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Received the Promising Developer Award from the Builder Council of India in 2014</h5>
                                        <p className="mb-0"></p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/20220531073708-Worldwide_Achievers.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Worldwide Achiever Awards - Saya Zion">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/20220531073708-Worldwide_Achievers.webp" alt="Worldwide Achievers" className="h-100 object-cover" title='Worldwide Achievers' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Worldwide Achiever Awards - Saya Zion</h5>
                                        <p className="mb-0">Worldwide Achievers</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/20220531074346-Pre-certification.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="LEED Certified - Saya Zion - Flats in Greater Noida West">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/20220531074346-Pre-certification.webp" alt="Pre Certification" className="h-100 object-cover" title='Pre Certification' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">LEED Certified - Saya Zion - Flats in Greater Noida West</h5>
                                        <p className="mb-0">Pre-certification</p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/2024/4.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Honoured with the Titan Achievers Awards by Hindustan Times in 2011">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/2024/4.webp" alt="Titan Achievers Awards By Hindustan Times, 20011" className="h-100 object-cover" title='Titan Achievers Awards By Hindustan Times, 20011' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Honoured with the Titan Achievers Awards by Hindustan Times in 2011</h5>
                                        <p className="mb-0"></p>
                                    </article>
                                </a>
                            </div>
                            <div className="swiper-slide newsBox blogBox">
                                <a href="https://sayahomes.com/assets/images/awards/2024/6.webp" className="inner h-100" data-magnify="awards" data-group="awards" data-caption="Awarded the Property Observer Jury Award by Worldwide Achiever">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/awards/2024/6.webp" alt="The Property Observer Jury Award By Worldwide Achiever" className="h-100 object-cover" title='The Property Observer Jury Award By Worldwide Achiever' /></div>
                                    <article>
                                        <h5 className="news-title mt-4 text-truncate">Awarded the Property Observer Jury Award by Worldwide Achiever</h5>
                                        <p className="mb-0"></p>
                                    </article>
                                </a>
                            </div>
                        </div>
                        <div className="bottomControls">
                            <div className="swiper-button-prev solid bg-secondary text-dark"></div>
                            {/* <div className="viewmore"><a href="#" className="button stroke white">View All Awards</a></div> */}
                            <div className="swiper-button-next solid bg-secondary text-dark"></div>
                        </div>
                    </div>
                </div> </div>
        </div>
    )
}

export default AwardsRecognition