import React, { useRef } from 'react';

const VideoModal = ({ showModal, modalData, handleCloseModal }) => {
  const iframeRef = useRef(null);

  if (!showModal) return null;

  return (
    <div className="modal testim-modal fade show" id="video-Modal" tabIndex="-1" role="dialog" aria-labelledby="sapnaModalTitle" aria-hidden="true" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content border-0 shadow rounded-0">
          <button type="button" className="close videoClose" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body">
            <h5 className="mb-3 text-serif text-secondary">{modalData.title}</h5>
            <iframe
              ref={iframeRef}
              width="100%"
              height="315"
              src={modalData.src}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
