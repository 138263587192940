import React, { useState } from 'react';
import axios from 'axios';
import { handleSubmit4QT } from './4QT-api';
import { useNavigate } from 'react-router-dom';

const ModalForm = ({ showModal, modalData, handleCloseModal }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newcreated = new Date().toISOString().slice(0, 19).replace('T', ' ');

    const dataToSend = {
      ...formData,
      projects: modalData.projectName,
      source: modalData.modalType,
      medium: modalData.pageName,
      campaign: modalData.brochure,
      created: newcreated
    };

    try {
      const response = await axios.post('https://ecis.in/apis/sayahomes/projectQuery/upload', dataToSend);
      await handleSubmit4QT(formData.mobile, formData.email, formData.name, modalData.pageName, modalData.pageName, formData.message);
      console.log('Form submitted successfully:', response.data);
      setSuccessMessage('Your enquiry has been submitted successfully.');
      setFormData({ name: '', mobile: '', email: '', message: '' });
      handleCloseModal();
      navigate('/', { replace: true });
      window.location.reload();
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('There was an error submitting your enquiry. Please try again.');
    }
  };

  return (
    showModal && (
      <div className="modal fade show" id="modalforms" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close d-flex align-items-center justify-content-center" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
              <span aria-hidden="true" className="ion-ios-close">×</span>
            </button>
            <div className="row no-gutters">
              <div className="col-md-4 modal-iconContainer d-none d-sm-block">
                <div className="modal-icons">
                  <h6>We Promise</h6>
                  <div className="modal-icon">
                    <div className="img-fluid">
                      <img src="./assets/images/icons/customer-service.svg" alt="Instant Call Back" />
                    </div>
                    <span>Instant Call Back</span>
                  </div>
                  <div className="modal-icon">
                    <div className="img-fluid">
                      <img src="./assets/images/icons/site-visit.svg" alt="Free Site Visit" />
                    </div>
                    <span>Free Site Visit</span>
                  </div>
                  <div className="modal-icon">
                    <div className="img-fluid">
                      <img src="./assets/images/icons/unmatched-price.svg" alt="Unmatched Price" />
                    </div>
                    <span>Unmatched Price</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="modal-body">
                  <h5 className="mb-3">If you have any query, fill the form.</h5>
                  {successMessage && <div className="alert alert-success">{successMessage}</div>}
                  {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                  <form className="modal-form" id="modalform" onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="col-md-12 form-group">
                        <label className="customCursor" htmlFor="mname">Name *</label>
                        <input type="text" className="form-control" id="mname" name="name" value={formData.name} onChange={handleChange} required />
                      </div>
                      <div className="col-md-12 form-group">
                        <label className="customCursor" htmlFor="mmobile">Mobile *</label>
                        <input type="tel" className="form-control" name="mobile" id="mmobile" value={formData.mobile} onChange={handleChange} required />
                      </div>
                      <div className="col-md-12 form-group">
                        <label className="customCursor" htmlFor="memail">Email *</label>
                        <input type="email" className="form-control" name="email" id="memail" value={formData.email} onChange={handleChange} required />
                      </div>
                      <div className="col-md-12 form-group">
                        <label className="customCursor" htmlFor="mquery">Query</label>
                        <textarea className="form-control" name="message" id="mmessage" value={formData.message} onChange={handleChange}></textarea>
                      </div>
                      <div className="col-md-12 form-group">
                        <p className="mb-0"><small><input type="checkbox" required /> I authorise Saya Group and its representatives to Call, SMS, Email or WhatsApp me about its projects and offers. This consent overrides any registration for DNC / NDNC.</small></p>
                      </div>
                      <div className="col-md-12 formFooter readmore">
                        <input type="hidden" name="mmodal" value="active" />
                        <input type="hidden" name="mpagename" id="mpagename" value={modalData.pageName} />
                        <input type="hidden" name="projectName" id="projectName" value={modalData.projectName} />
                        <input type="hidden" name="mbrochure" id="mbrochure" value={modalData.brochure} />
                        <input type="hidden" name="brouchureurl" id="brouchureurl" value={modalData.brochureUrl} />
                        <button type="submit" className="button" id="modalsubmit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalForm;
