import React, { useState } from 'react';
import Navbar from '../components/navbar';
import FooterAll from '../components/footer2';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function NriCorner() {
    const canonical = window.location.href.replace(/^https?:\/\/www\./, 'https://');

  const [activeIndex, setActiveIndex] = useState(null);
    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

  return (
    <div>
      <Helmet>
      <title>Saya Homes | NRI Corner</title>
      <meta name="description" content="NRI Corner" />
      <meta name="keywords" content="NRI Corner, NRI FAQs" />
      <link rel="canonical" href={canonical}/>
      </Helmet>
      <Navbar />
      <div className="emptyBox"></div>
      <div className="pageTitle py-5 bg-orange w-100">
        <div className="container-lg">
          <h1 className="display-4 mb-0">NRI Corner</h1>
        </div>
      </div>
      <div className="breadcrumbContainer">
        <div className="container-lg">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">NRI Corner</li>
          </ol>
        </div>
      </div>
      <div className="w-100 padding position-relative">
        <div className="container-lg">
          <p>We have put together the list of most relevant questions from an NRI/PlO’s perspective.</p>
          <div className="faqs-box">
              <div className={`faqs_question ${activeIndex === 0 ? 'active' : ''}`} onClick={() => toggleFAQ(0)}>
                Who is classified as an NRI? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 0 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Non Resident Indian is a person resident outside India who is either a citizen of India or a person of Indian origin. A NRI is an Indian Citizen who has migrated to another country. For all official purpose the Government of India considers Indian National away from India for more than 182 days, during the course of the preceding financial year.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 1 ? 'active' : ''}`} onClick={() => toggleFAQ(1)}>
              Who is classified as a PIO? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 1 && (
                  <div className="faqs_answer">
                      <article>
                          <p>A Person of Indian Origin means an individual (not being a citizen of the following countries; Pakistan, Bangladesh, Sri Lanka, Afghanistan, China, Iran, Nepal, Bhutan) who:</p>
                          <ul className='list'>
                            <li>Held an Indian Passport at any time, or</li>
                            <li>Who or either of whose father or grandfather was a citizen of India by virtue of the Constitution of India or the Citizenship Act, 1955</li>
                          </ul>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 2 ? 'active' : ''}`} onClick={() => toggleFAQ(2)}>
              Does NRI/PIO require permission of The Reserve Bank to acquire residential/ commercial property in India? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 2 && (
                  <div className="faqs_answer">
                      <article>
                          <p>No.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 3 ? 'active' : ''}`} onClick={() => toggleFAQ(3)}>
              How many residential / commercial properties can NRI / PIO purchase under the general permission? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 3 && (
                  <div className="faqs_answer">
                      <article>
                          <p>There are no restrictions on the number of residential / commercial properties that can be purchased.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 4 ? 'active' : ''}`} onClick={() => toggleFAQ(4)}>
              Do any documents need to be filed with Reserve Bank of India after purchase? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 4 && (
                  <div className="faqs_answer">
                      <article>
                          <p>An NRI / PIO who has purchased residential / commercial property under general permission, is not required to file any documents with the Reserve Bank.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 5 ? 'active' : ''}`} onClick={() => toggleFAQ(5)}>
              Whether immovable property in India can be acquired by way of gift? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 5 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Yes, NRIs and PIOs can freely acquire immovable property by way of gift.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 6 ? 'active' : ''}`} onClick={() => toggleFAQ(6)}>
              Can NRI / PIO rent out the residential / commercial property purchased out of foreign exchange / rupee funds? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 6 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Yes, NRI/PIO can rent out the property without the approval of the Reserve Bank.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 7 ? 'active' : ''}`} onClick={() => toggleFAQ(7)}>
              Whether a non-resident can inherit immovable property in India? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 7 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Yes.</p>
                      </article>
                  </div>
              )}

              <h4>Property Purchase / Sale</h4>
              <p>Acquisition of Immovable Property in India.</p>
              <div className={`faqs_question ${activeIndex === 8 ? 'active' : ''}`} onClick={() => toggleFAQ(8)}>
              Who can purchase immovable property in India? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 8 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Under the general permission available, the following can purchase immovable property in India:</p>
                          <ul className='list mb-4'>
                            <li>Non-Resident Indian (NRI)</li>
                            <li>Person of Indian Origin (PIO)</li>
                          </ul>
                          <p>The general permission, however, covers only purchase of residential and commercial property and not for purchase of agricultural land / plantation property / farmhouse in India.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 9 ? 'active' : ''}`} onClick={() => toggleFAQ(9)}>
              Whether NRI/PIO can acquire agricultural land/ plantation property / farm house in India? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 9 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Such proposals will require specific approval of Reserve Bank and the proposals are considered in consultation with the Government of India.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 10 ? 'active' : ''}`} onClick={() => toggleFAQ(10)}>
              How many residential / commercial properties can NRI / PIO purchase under the general permission? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 10 && (
                  <div className="faqs_answer">
                      <article>
                          <p>There are no restrictions on the number of residential / commercial properties that can be purchased.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 11 ? 'active' : ''}`} onClick={() => toggleFAQ(11)}>
              Can a foreign national of non-Indian origin be a second holder to immovable property purchased by NRI / PIO? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 11 && (
                  <div className="faqs_answer">
                      <article>
                          <p>No.</p>
                      </article>
                  </div>
              )}

              <h4>Mode of Payment for Purchase/ Loan</h4>
              <div className={`faqs_question ${activeIndex === 12 ? 'active' : ''}`} onClick={() => toggleFAQ(12)}>
              How can an NRI / PIO make payment for purchase of residential / commercial property in India? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 12 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Following options exist for making payment for purchase of the property:</p>
                          <ul className='list'>
                            <li>Funds remitted to India through normal banking channel or</li>
                            <li>Funds held in NRE / FCNR (B) / NRO account maintained in India</li>
                          </ul>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 13 ? 'active' : ''}`} onClick={() => toggleFAQ(13)}>
              Can NRI / PIO avail of loan from an authorised dealer for acquiring flat / house in India for his own residential use against the security of funds held in his NRE Fixed Deposit account / FCNR (B) account? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 13 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Yes, such loans are subject to the terms and conditions as laid down in Schedules 1 and 2 to Notification No. FEMA 5/2000-RB dated May 3, 2000 as amended from time to time.</p>
                      </article>
                  </div>
              )}

              <div className={`faqs_question ${activeIndex === 14 ? 'active' : ''}`} onClick={() => toggleFAQ(14)}>
              Can such loans be repaid? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 14 && (
                  <div className="faqs_answer">
                      <article>
                          <ul className='list'>
                            <li>By way of inward remittance through normal banking channel or</li>
                            <li>By debit to his NRE / FCNR (B) / NRO account or</li>
                            <li>Out of rental income from such property</li>
                            <li>By the borrower’s close relatives, as defined in section 6 of the Companies Act, 1956, through their account in India by crediting the borrower’s loan account.</li>
                          </ul>
                      </article>
                  </div>
              )}

              <h4>Property Sale</h4>
              <div className={`faqs_question ${activeIndex === 15 ? 'active' : ''}`} onClick={() => toggleFAQ(15)}>
              Can an NRI/ PIO/foreign national sell his residential / commercial property? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 15 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Yes. Reserve Bank of India has granted general permission for sale of such property.</p>
                      </article>
                  </div>
              )}
              <div className={`faqs_question ${activeIndex === 16 ? 'active' : ''}`} onClick={() => toggleFAQ(16)}>
              Can an agricultural land / plantation property / farm house in India owned / held by a non-resident be sold? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 16 && (
                  <div className="faqs_answer">
                      <article>
                          <p>NRI / PIO may sell agricultural land /plantation property/farm house to a person resident in India who is a citizen of India.</p>
                      </article>
                  </div>
              )}
              <div className={`faqs_question ${activeIndex === 17 ? 'active' : ''}`} onClick={() => toggleFAQ(17)}>
              Can a non-resident gift his residential / commercial property? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 17 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Yes, NRI / PIO may gift residential / commercial property.</p>
                      </article>
                  </div>
              )}

              <h4>Repatriation</h4>
              <div className={`faqs_question ${activeIndex === 18 ? 'active' : ''}`} onClick={() => toggleFAQ(18)}>
              Property acquired of foreign Exchange source? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 18 && (
                  <div className="faqs_answer">
                      <article>
                          <p>In case the amount has been received from inward remittance or debit to NRE/FCNR (B)/NRO account for acquiring the property or for repayment of the loan, the principal amount can be repatriated outside India. Any Capital Gain is credited to NRO A/C. However, repatriation of sale proceeds purchased out of foreign exchange is restricted to not more than two residential properties, in a block of one year.</p>
                      </article>
                  </div>
              )}
              <div className={`faqs_question ${activeIndex === 19 ? 'active' : ''}`} onClick={() => toggleFAQ(19)}>
              Property acquired out of Rupee source? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 19 && (
                  <div className="faqs_answer">
                      <article>
                          <p>In case the property is acquired out of Rupee resources and/or the loan is repaid by close relatives in India (as defined in Section 6 of the Companies Act, 1956), the amount can be credited to the NRO account of the NRI/PIO. The amount of capital gains, if any, arising out of sale of the property can also be credited to the NRO account. NRI/PIO are also allowed by the Authorised Dealers to repatriate an amount up to USD 1 million per financial year out of the balance in the NRO account for all bonafide purposes to the satisfaction of the authorised dealers, subject to tax compliance.</p>
                      </article>
                  </div>
              )}
              <div className={`faqs_question ${activeIndex === 20 ? 'active' : ''}`} onClick={() => toggleFAQ(20)}>
              Repatriation of rental income? <span><i className="fa fa-angle-down"></i></span>
              </div>
              {activeIndex === 20 && (
                  <div className="faqs_answer">
                      <article>
                          <p>Repatriation of income derived out of letting of immovable property is permissible. NRI/PIO can rent out the property without approval of Reserve Bank. Rent received can be credited to NRO/NRE account or remitted abroad. Powers have been delegated to the Authorised Dealers to allow repatriation of current income like rent, interest, dividend etc. of NRI/PIO who do not maintain an NRO account is based on an appropriate certification by Chartered Accountant, certifying that the amount proposed to be remitted is eligible for remittance and that applicable taxes have been paid/ provided for.</p>
                      </article>
                  </div>
              )}
          </div>
        </div>
      </div>
      <FooterAll />
    </div>
  );
}

export default NriCorner;
